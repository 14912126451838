<template>
  <div class="profile-data-wrapper" v-show="!loading">
    <template
      v-if="
        userPermissions &&
        userPermissions.billing &&
        userPermissions.billing.length > 0 &&
        userPermissions.billing.includes('View')
      "
    >
      <div class="profile-options" style="margin-bottom: 40px">
        <div class="row">
          <div class="col">
            <router-link
              class="btn primary-btn"
              :to="{ name: 'ProfileRosterContracts', params: $route.params.ID }"
              v-if="
                userPermissions &&
                userPermissions.contracts &&
                userPermissions.contracts.length > 0 &&
                userPermissions.contracts.includes('View')
              "
              ><font-awesome-icon :icon="['fas', 'arrow-left']"
            /></router-link>
          </div>
          <div class="col flex-grow-1 d-flex justify-content-end">
            <div class="date-field">
              <datepicker
                v-model="billingDate"
                :maxDate="new Date()"
                :enableTimePicker="false"
                autoApply
                :clearable="false"
                :format="format"
              >
                <template #input-icon>
                  <span class="lnr lnr-calendar-full"></span>
                </template>
              </datepicker>
            </div>
          </div>
        </div>
      </div>
      <h2 class="section-heading">Hourly Projects</h2>
      <div class="profile-table custom-table">
        <table class="table datatable">
          <thead>
            <tr>
              <th class="no-sort">Contract Name</th>
              <th class="no-sort">Client Name</th>
              <th class="no-sort">Hours</th>
              <th class="no-sort">Minutes</th>
              <th class="no-sort">Amount ($)</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(contract, index) in HourlyContracts" :key="index">
              <td>{{ contract.Title }}</td>
              <td>{{ contract.ContactPerson }}</td>
              <td>
                <div class="row grid-1">
                  <div class="col">
                    <input
                      class="form-control"
                      type="text"
                      v-on:keypress="NumbersOnly"
                      :placeholder="0"
                      :value="HourlyContracts[index].billing.Hours"
                      @input="updateHoursValue($event, index)"
                      :disabled="contract.ContractType === 'Fixed Price'"
                    />
                  </div>
                </div>
              </td>
              <td>
                <div class="row grid-1">
                  <div class="col">
                    <input
                      class="form-control"
                      type="text"
                      v-on:keypress="NumbersOnly"
                      :placeholder="0"
                      :value="HourlyContracts[index].billing.Minutes"
                      @input="updateMinutesValue($event, index)"
                      :disabled="contract.ContractType === 'Fixed Price'"
                    />
                    <span class="error" v-if="errors.Minutes[index]">{{
                      errors.Minutes[index]
                    }}</span>
                  </div>
                </div>
              </td>
              <td>
                <div class="row grid-1">
                  <div class="col">
                    <div class="hourly-rate-field">
                      <span class="field-icon">$</span>
                      <input
                        class="form-control"
                        type="text"
                        :value="HourlyContracts[index].billing.Amount"
                        @input="updateAmountValue($event, index)"
                        :disabled="contract.ContractType === 'Hourly'"
                      />
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <h2
        class="section-heading"
        v-if="FixedContracts && FixedContracts.length > 0"
      >
        Fixed Projects
      </h2>
      <div
        class="profile-table custom-table"
        v-if="FixedContracts && FixedContracts.length > 0"
      >
        <table class="table datatable">
          <thead>
            <tr>
              <th class="no-sort">Contract Name</th>
              <th class="no-sort">Client Name</th>
              <th class="no-sort">Milestone</th>
              <th class="no-sort">Amount ($)</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(contract, index) in FixedContracts" :key="index">
              <td>{{ contract.Title }}</td>
              <td>{{ contract.ContactPerson }}</td>
              <td>
                <div class="row grid-1">
                  <div class="col">
                    <select
                      class="form-control select-box"
                      v-model="FixedContracts[index].milestone.Id"
                      @change="updateMilestoneAmountValue($event, index)"
                      :disabled="
                        FixedContracts[index].billing &&
                        FixedContracts[index].billing.MilestoneId ===
                          FixedContracts[index].milestone.Id
                          ? true
                          : false
                      "
                    >
                      <option value="">Select Milestone</option>
                      <template
                        v-for="(data, index) in FixedContracts[index]
                          .milestones"
                        :key="index"
                      >
                        <option
                          :value="data.Id"
                          :disabled="
                            data.Status === 'Complete'
                              ? true
                              : data.Status === 'Incomplete'
                              ? false
                              : true
                          "
                        >
                          {{ data.Name }}
                        </option>
                      </template>
                    </select>
                  </div>
                </div>
              </td>
              <td>
                <div class="row grid-1">
                  <div class="col">
                    <div class="hourly-rate-field">
                      <span class="field-icon">$</span>
                      <input
                        class="form-control"
                        type="text"
                        :value="FixedContracts[index].milestone.Amount"
                        readonly
                        :disabled="true"
                      />
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="profile-options" v-if="HourlyContracts.length > 0">
        <div class="row">
          <div class="col flex-grow-1 d-flex justify-content-end">
            <a
              class="btn primary-btn"
              href="javascript:void(0);"
              @click.prevent="submitHandler()"
              :disabled="errors.Minutes.length > 0 || errors.Hours.length > 0"
              v-if="userPermissions.billing.includes('Update')"
              >Submit Daily Billing</a
            >
          </div>
        </div>
      </div>
    </template>
    <template
      v-if="
        userPermissions &&
        userPermissions.billing &&
        (userPermissions.billing.length === 0 ||
          (userPermissions.billing.length > 0 &&
            !userPermissions.billing.includes('View')))
      "
      >Permission Denied.</template
    >
  </div>
</template>
<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

library.add(faArrowLeft);

import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.html5.js";
import $ from "jquery";
import common from "../mixins/common";
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import billingService from "../services/billing.service";
import { ref } from "vue";

export default {
  name: "ProfileRosterContracts",
  setup() {
    const date = ref(new Date());
    // In case of a range picker, you'll receive [Date, Date]
    const format = (date) => {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      return `${day}/${month < 10 ? "0" + month : month}/${year}`;
    };

    return {
      format,
      billingDate: date,
    };
  },
  data: () => ({
    loading: true,
    profile: {
      Id: "",
      Name: "",
      HourlyRate: "",
    },
    HourlyContracts: [],
    FixedContracts: [],
    errors: {
      Hours: [],
      Minutes: [],
    },
  }),
  mixins: [common],
  components: {
    Datepicker,
    FontAwesomeIcon,
  },
  methods: {
    updateHoursValue(event, index) {
      const value = event.target.value;
      if (value <= 999) {
        this.HourlyContracts[index].billing.Hours = value;
      }
      this.calculateAmount(index);
      this.$forceUpdate();
    },
    updateMinutesValue(event, index) {
      const value = event.target.value;
      this.HourlyContracts[index].billing.Minutes = value;
      this.calculateAmount(index);
      this.validateMinutes(event, index);
      this.$forceUpdate();
    },
    updateAmountValue(event, index) {
      const value = event.target.value
        ? event.target.value % 1 === 0
          ? Math.round(event.target.value)
          : event.target.value.toFixed(2)
        : "";
      if (value <= 999999) {
        this.HourlyContracts[index].billing.Amount = value;
      }
      this.$forceUpdate();
    },
    updateMilestoneAmountValue(event, index) {
      const value = event.target.value;
      if (value) {
        const milestones = this.FixedContracts[index].milestones;

        const findMilestone = milestones.find(function (milestone) {
          if (milestone.Id === value) return milestone;
        });

        if (findMilestone && findMilestone.Amount > 0) {
          this.FixedContracts[index].milestone.Amount = findMilestone.Amount
            ? findMilestone.Amount % 1 === 0
              ? Math.round(findMilestone.Amount)
              : findMilestone.Amount.toFixed(2)
            : "";
        } else {
          this.FixedContracts[index].milestone.Amount = 0;
        }
      } else {
        this.FixedContracts[index].milestone.Amount = 0;
      }
      this.$forceUpdate();
    },
    calculateAmount(index) {
      if (this.HourlyContracts[index].ContractType === "Hourly") {
        const rate = this.HourlyContracts[index].HourlyRate
          ? this.HourlyContracts[index].HourlyRate
          : this.profile.HourlyRate;
        const hours = this.HourlyContracts[index].billing.Hours;
        const minutes = this.HourlyContracts[index].billing.Minutes;

        const Hours = hours > 0 ? parseFloat(hours) : 0;
        const minToHours = minutes > 0 ? parseFloat(minutes / 60) : 0;
        const Amount = rate * (Hours + minToHours);
        const finalAmount = isNaN(Amount)
          ? 0
          : Amount % 1 === 0
          ? Math.round(Amount)
          : Amount.toFixed(2);
        this.HourlyContracts[index].billing.Amount = finalAmount;
        return finalAmount;
      } else {
        return 0;
      }
    },
    async submitHandler() {
      let vm = this;
      try {
        if (vm.errors.Hours.length > 0 || vm.errors.Minutes.length > 0) {
          return;
        }

        vm.$store.commit("loadingStatus", true);

        let HourlyContracts = [];

        for (let index = 0; index < vm.HourlyContracts.length; index++) {
          const element = vm.HourlyContracts[index];

          const setformData = {
            BillingId: "",
            ContractId: element.ContractId,
            Date: vm.$filters.dateFormat(vm.billingDate, "YYYY-MM-DD"),
            Hours: element.billing.Hours,
            Minutes: element.billing.Minutes,
            Amount:
              element.ContractType === "Hourly"
                ? vm.calculateAmount(index)
                : element.billing.Amount,
          };

          if (element.billing.Id) {
            setformData.BillingId = element.billing.Id;
          } else {
            delete setformData.BillingId;
          }

          HourlyContracts.push(setformData);
        }

        let FixedContracts = [];

        for (let index = 0; index < vm.FixedContracts.length; index++) {
          const element = vm.FixedContracts[index];

          const setformData = {
            ContractId: element.ContractId,
            MilestoneId: element.milestone.Id,
            Date: vm.$filters.dateFormat(vm.billingDate, "YYYY-MM-DD"),
          };

          if (
            (!element.billing ||
              (element.billing && !element.billing.MilestoneId)) &&
            element.milestone.Id
          ) {
            FixedContracts.push(setformData);
          }
        }

        const formData = {
          HourlyContracts,
          FixedContracts,
        };

        const response = await billingService.addBilling(formData);
        vm.getContracts();
        vm.toastMessage(response.message, "success");
        vm.$store.commit("loadingStatus", false);
      } catch (error) {
        const message = vm.errorMessage(error);
        vm.toastMessage(message, "error");
        vm.$store.commit("loadingStatus", false);
      }
    },
    async getContracts() {
      let vm = this;
      const userPermissions = await vm.userPermissions;
      if (
        userPermissions &&
        userPermissions.billing &&
        userPermissions.billing.length > 0
      ) {
        try {
          vm.$store.commit("loadingStatus", true);

          const formData = {
            uprosterProfileId: vm.$route.params.ID,
            date: vm.$filters.dateFormat(vm.billingDate, "YYYY-MM-DD"),
          };

          const response = await billingService.billingContracts(formData);

          const HourlyContracts = response.hourly;
          const FixedContracts = response.fixed;

          for (let index = 0; index < HourlyContracts.length; index++) {
            if (
              HourlyContracts[index].billing &&
              HourlyContracts[index].billing.Id
            ) {
              HourlyContracts[index].billing.Hours =
                HourlyContracts[index].billing.Hours > 0
                  ? Math.round(HourlyContracts[index].billing.Hours)
                  : "";
              HourlyContracts[index].billing.Minutes =
                HourlyContracts[index].billing.Minutes > 0
                  ? Math.round(HourlyContracts[index].billing.Minutes)
                  : "";
              HourlyContracts[index].billing.Amount =
                HourlyContracts[index].billing.Amount > 0
                  ? Math.round(HourlyContracts[index].billing.Amount)
                  : 0;
            } else {
              HourlyContracts[index].billing = {
                Id: "",
                Hours: "",
                Minutes: "",
                Amount: 0,
              };
            }
          }

          for (let index = 0; index < FixedContracts.length; index++) {
            const MilestoneId =
              FixedContracts[index].billing &&
              FixedContracts[index].billing.MilestoneId
                ? FixedContracts[index].billing.MilestoneId
                : "";
            const findMilestone = FixedContracts[index].milestones.find(
              function (milestone) {
                if (milestone.Id === MilestoneId) return milestone;
              }
            );

            const Amount = findMilestone ? Math.round(findMilestone.Amount) : 0;

            FixedContracts[index].milestone = {
              Id: MilestoneId,
              Amount: Amount,
            };
          }

          vm.HourlyContracts = HourlyContracts;
          vm.FixedContracts = FixedContracts;

          vm.profile.Name = response.profileData.ProfileName;
          vm.profile.Id = response.profileData.Id;
          vm.profile.HourlyRate =
            response.profileData.profile_professional_detail.HourlyRate;
          vm.$store.commit("username", response.profileData.ProfileName);

          var dT = $(".datatable").DataTable();
          dT.destroy();

          vm.$nextTick(function () {
            $(".datatable").DataTable({
              iDisplayLength: 25,
              bLengthChange: false,
              /* Disable initial sort */
              aaSorting: [],
              ordering: false,
              info: false,
              fnDrawCallback: function (oSettings) {
                if (oSettings._iDisplayLength >= oSettings.fnRecordsDisplay()) {
                  $(oSettings.nTableWrapper)
                    .find(".dataTables_paginate")
                    .hide();
                } else {
                  $(oSettings.nTableWrapper)
                    .find(".dataTables_paginate")
                    .show();
                }
              },
            });
            $(".dt-buttons, .dataTables_filter").hide();
            this.isLoaded = true;
            vm.$store.commit("loadingStatus", false);
          });
        } catch (error) {
          const message = vm.errorMessage(error);
          vm.toastMessage(message, "error");
          vm.$store.commit("loadingStatus", false);
        }
      }
    },
    downloadCsv: async function () {
      $(".buttons-csv").click();
    },
    beforeUnmount() {
      this.$store.commit("username", "");
    },
    NumbersOnly(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    validateMinutes($event, index) {
      const value = $event.target.value;
      this.errors.Minutes.splice(index, 1);
      if (value > 59) {
        this.errors.Minutes[index] = "Minutes between 0 to 59";
      }
    },
  },
  async mounted() {
    this.$store.commit("username", "");
    await this.getContracts();
    this.loading = false;
  },
  watch: {
    billingDate: {
      handler() {
        this.errors.Hours = [];
        this.errors.Minutes = [];
        this.getContracts();
      },
    },
    userPermissions: {
      handler() {
        this.getContracts();
      },
      deep: true,
    },
  },
};
</script>

